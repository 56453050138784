var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-overview" },
    [
      _c(
        "el-container",
        { staticStyle: { border: "1px solid #dddfe0" } },
        [
          _c(
            "el-header",
            { staticStyle: { "border-bottom": "1px solid #dddfe0" } },
            [
              _c("el-button", {
                staticClass: "back",
                staticStyle: { "margin-right": "20px" },
                attrs: { icon: "el-icon-back", circle: "" },
                on: {
                  click: function($event) {
                    _vm.$back()
                  }
                }
              }),
              _vm._v("合同详情\n    ")
            ],
            1
          ),
          _c("el-main", [
            _c("div", { staticClass: "odiv" }, [
              _c("h2", [_vm._v("房屋租赁合同书")]),
              _c("span", { staticClass: "g" }, [_vm._v("（住 宅）")]),
              _c("div", [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-right": "10%"
                    }
                  },
                  [_vm._v("\n            房屋租赁管理办公室制\n            ")]
                )
              ]),
              _vm._v(
                "\n        出租方(甲方)： " +
                  _vm._s(_vm.landlordName) +
                  "\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        房屋地址： " +
                  _vm._s(_vm.address) +
                  _vm._s(_vm.apartmentName) +
                  "小区 " +
                  _vm._s(_vm.buildNo) +
                  "栋 " +
                  _vm._s(_vm.unitNo ? _vm.unitNo + "单元" : "") +
                  "\n        " +
                  _vm._s(_vm.roomNo) +
                  "室\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        联系电话： " + _vm._s(_vm.lphone) + "\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        身份证号码： " + _vm._s(_vm.lidcardNo) + "\n        "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                "\n        承租方(乙方)： " +
                  _vm._s(_vm.tenantName) +
                  "\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        联系电话： " + _vm._s(_vm.tphone) + "\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        身份证号码： " + _vm._s(_vm.tidcardNo) + "\n        "
              ),
              _c("br"),
              _c("br"),
              _vm._v(
                "根据《中华人民共和国合同法》、《商品房屋租赁管理办法》、《人民代表大会常务委员会关于加强房屋租赁安全责任的决定》的规定，经甲、乙双方协商一致，订立本合同。\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        第一条 甲方将位于" +
                  _vm._s(_vm.address) +
                  _vm._s(_vm.apartmentName) +
                  " 小区 " +
                  _vm._s(_vm.buildNo) +
                  " 栋 " +
                  _vm._s(_vm.unitNo ? _vm.unitNo + "单元" : "") +
                  " " +
                  _vm._s(_vm.roomNo) +
                  " 室的房屋(以下简称租赁房屋)出租给乙方使用，房屋租赁用途为居住 。房屋租赁面积共计 " +
                  _vm._s(_vm.area) +
                  "平方米。\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        第二条 乙方租用租赁房屋的期限自 " +
                  _vm._s(_vm.startDate1) +
                  " 年 " +
                  _vm._s(_vm.startDate2) +
                  " 月 " +
                  _vm._s(_vm.startDate3) +
                  "日至 " +
                  _vm._s(_vm.endDate1) +
                  " 年 " +
                  _vm._s(_vm.endDate2) +
                  " 月" +
                  _vm._s(_vm.endDate3) +
                  "\n        日止。租赁期限不得超过二十年。超过二十年的，超过部分无效。租赁期间届满，当事人可以续订租赁合同，但约定的租赁期限自续订之日起不得超过二十年。\n        "
              ),
              _c("br"),
              _vm._v(
                "甲方应自合同签订起五日内将租赁房屋交付乙方。交付租赁房屋时，双方应就租赁房屋及其附属设施的当时状况、附属财产等有关情况进行确认，并在以下表格列明：\n        "
              ),
              _c("br"),
              _c(
                "table",
                {
                  staticClass: "contract__table",
                  style: !_vm.isInventoryList ? "border-bottom:0" : ""
                },
                [
                  _c("thead", [
                    _c("th", [_vm._v("名称")]),
                    _c("th", [_vm._v("数量")]),
                    _c("th", [_vm._v("品牌")]),
                    _c("th", [_vm._v("原价")])
                  ]),
                  _vm.isInventoryList
                    ? _c(
                        "tbody",
                        _vm._l(_vm.inventoryList, function(item, index) {
                          return _c("tr", { key: index }, [
                            _c("td", [_vm._v(_vm._s(item.itemName))]),
                            _c("td", [_vm._v(_vm._s(item.itemQuantity))]),
                            _c("td", [_vm._v(_vm._s(item.itemBrand))]),
                            _c("td", [_vm._v(_vm._s(item.itemPrice))])
                          ])
                        }),
                        0
                      )
                    : _vm._e()
                ]
              ),
              !_vm.isInventoryList
                ? _c("div", { staticClass: "contract__table-tbMsg" }, [
                    _vm._v("暂无清单列表")
                  ])
                : _vm._e(),
              _c("div", { staticStyle: { color: "red" } }, [
                _vm._v(
                  "物品清单说明：" +
                    _vm._s(
                      _vm.itemDescription
                        ? _vm.itemDescription
                        : "为了给您提供一个“温馨的家”，以上物品在您租住期间免费供您使用，请您务必爱护，如物品有非正常使用情况下损坏时，将酌情按照物品折扣价格赔偿。如电器损坏、浴巾浴袍或床上用品染色等，需要一定的金额赔偿。"
                    )
                )
              ]),
              _vm._v(
                "\n        第三条 租赁房屋月租金总额为人民币 " +
                  _vm._s(_vm.monthlyAmount) +
                  " 元（大写： " +
                  _vm._s(_vm.monthlyAmount1) +
                  "）。其中房租为每月固定人民币 " +
                  _vm._s(_vm.rental_fee) +
                  " 元（大写：" +
                  _vm._s(_vm.rental_fee1) +
                  "\n        ），押金为首月人民币" +
                  _vm._s(_vm.deposit) +
                  "元（大写：" +
                  _vm._s(_vm.deposit1) +
                  "），物业费为人民币 " +
                  _vm._s(_vm.propertyFee) +
                  " 元（大写：" +
                  _vm._s(_vm.propertyFee1) +
                  "\n        ）。房屋租赁合同期内，甲方不得单方面提高租金。\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        乙方应于每月 " +
                  _vm._s(_vm.payDate) +
                  " 日前向甲方交付租金。\n        "
              ),
              _c("br"),
              _vm._v(
                "\n        第四条 乙方同意，在甲方交付租赁房屋时向甲方支付 1 个月（押一付一）租金数额的租赁押金，即人民币 " +
                  _vm._s(_vm.deposit) +
                  " 元（大写： " +
                  _vm._s(_vm.deposit1) +
                  "）。\n        "
              ),
              _c("br"),
              _vm._v(
                "本合同约定的各项条款，甲乙双方均须自觉履行，如有一方违约，按法律规定承担违约责任。\n        "
              ),
              _c("br"),
              _vm._v(
                "第五条 租赁期间，甲方负责支付法律、法规规定应交纳的房屋租赁相关的税费。乙方负责支付因使用租赁房屋产生的水电费、卫生费、房屋物业管理费等费用。\n        "
              ),
              _c("br"),
              _vm._v(
                "第六条 甲方应保证租赁房屋及其内部设施的安全和正常使用。\n        "
              ),
              _c("br"),
              _vm._v(
                "该房屋的维修责任除双方在本合同或本合同补充条款中约定之外，均由甲方负责。甲方维修租赁房屋及其附属设施，应提前五天书面通知乙方，乙方应积极协助和配合。\n        "
              ),
              _c("br"),
              _vm._v(
                "租赁房屋及其内部设施无法正常使用或出现故障时，乙方应及时通知甲方维修并采取有效措施，甲方应于接到乙方通知后五日内进行维修。甲方接到通知后不在上述约定的时间内履行维修义务的，乙方可代为维修，维修费用由甲方承担。甲方拒不承担费用的，乙方可在租金中进行抵扣。\n        如因不可抗力原因，导致房屋损坏或造成乙方损失的，双方互不承担责任。\n        "
              ),
              _c("br"),
              _vm._v(
                "第七条 乙方应正常、合理使用租赁房屋及其附属的设施。因乙方使用不当或不合理使用，租赁房屋或附属的设施出现损坏或发生故障，乙方应负责及时维修或赔偿。\n        除房屋内已有装修和设施外，乙方因使用需要，在不影响房屋主体结构的前提下，经征得甲方同意，可以对租赁房屋进行装修，装修费用由乙方承担。按规定应向有关部门（包括该房屋物业管理机构）办理申报手续后方可施工。\n        "
              ),
              _c("br"),
              _vm._v(
                "乙方如改变房屋的内部结构、装修或设置对房屋结构有影响的设备，设计规模、范围、工艺、用料等方案均须事先征得甲方的书面同意后，按规定应向有关部门（包括该房屋物业管理机构）办理申报手续后方可施工。租赁期满后或因乙方责任导致退租的，除双方另有约定外，甲方有权选择以下权利中的一种：依附于房屋的装修归甲方所有。\n        "
              ),
              _c("br"),
              _vm._v(
                "第八条 未经甲方书面同意，乙方不得将租赁房屋部分或全部转租与他人。经甲方书面同意转租的，转租合同的终止日期不得超过本合同规定的终止日期。\n        转租期间，乙方除可享有并承担转租合同规定的权利和义务外，还应继续履行本合同规定的义务。转租期间，本合同发生变更、解除或终止时，转租合同也应随之相应变更、解除或终止。\n        "
              ),
              _c("br"),
              _vm._v("第九条 变更和解除本合同的条件：\n        "),
              _c("br"),
              _vm._v(
                "在租赁期限内，有下列情况之一的，可以变更或者解除本合同：\n        "
              ),
              _c("br"),
              _vm._v("（1）经双方协商一致\n        "),
              _c("br"),
              _vm._v("（2）政府征用、收回或拆除租赁房屋\n        "),
              _c("br"),
              _vm._v("（3）因不可抗力因素致使本合同不能继续履行的。\n        "),
              _c("br"),
              _vm._v(
                "第十条 本合同期满终止（或因其它原因导致本合同终止）后，乙方应于本合同终止后五日内迁离租赁房屋。\n        "
              ),
              _c("br"),
              _vm._v(
                "乙方迁离租赁房屋时，应当将自身物品一并搬出。乙方迁离后滞留在租赁房屋的物品由甲方处置。\n        "
              ),
              _c("br"),
              _vm._v(
                "第十一条 乙方需继续租用租赁房屋的，应于租赁期届满之日前30天内向甲方提出续租要求；在同等条件下，乙方对租赁房屋有优先承租权。\n        "
              ),
              _c("br"),
              _vm._v(
                "甲、乙双方就续租达成协议的，应重新订立合同，并到房屋租赁主管机关重新办理房屋租赁登记备案。\n        "
              ),
              _c("br"),
              _vm._v("第十二条 违约责任\n        "),
              _c("br"),
              _vm._v(
                "（一）在租赁期内，甲方有下列行为之一的，应承担如下违约责任：\n        "
              ),
              _c("br"),
              _vm._v(
                "1、甲方未按本合同约定的时间，交付该房屋供乙方使用的，每逾期一天，甲方应按合同租金总额的万分之五向乙方偿付违约金。逾期超过15天，则视甲方不履行本合同，乙方有权解除合同，退回押金，甲方除应按上述规定支付违约金外，若支付的违约金不足抵付乙方损失的，甲方还应负责赔偿。\n        "
              ),
              _c("br"),
              _vm._v(
                "2、在租赁期限内，因甲方不及时履行本合同约定的维修、养护责任，致使该房屋发生损坏，造成乙方财产损失或人身伤害的，甲方应承担赔偿责任。\n        "
              ),
              _c("br"),
              _vm._v(
                "3、在租赁期限内，甲方擅自解除本合同，提前收回该房屋的，甲方应退回押金，并按月租金的二倍向乙方支付违约金，若支付的违约金不足抵付乙方损失的，甲方还应负责赔偿。\n        "
              ),
              _c("br"),
              _vm._v(
                "（二）在租赁期内，乙方有下列行为之一的，应承担如下违约责任：\n        "
              ),
              _c("br"),
              _vm._v(
                "1、擅自改变本合同规定的租赁用途，甲方有权解除合同，押金不退；若造成甲方损失的，乙方还应负责赔偿；\n        "
              ),
              _c("br"),
              _vm._v(
                "2、利用该房屋从事违法犯罪活动的，甲方有权解除合同，押金不退；若造成甲方损失的，乙方还应负责赔偿；\n        "
              ),
              _c("br"),
              _vm._v(
                "3、拖欠租金的，每逾期一天，乙方应按合同租金总额的万分之五向甲方偿付违约金；逾期超过30天，则视乙方不履行本合同，甲方有权解除合同，押金不退；乙方除应按上述规定支付违约金外，若支付的违约金不足抵付甲方损失的，乙方还应负责赔偿；\n        "
              ),
              _c("br"),
              _vm._v(
                "4、在租赁期限内，乙方未经甲方同意，中途擅自退租的，押金不退；若造成甲方损失的，乙方还应负责赔偿；\n        "
              ),
              _c("br"),
              _vm._v(
                "5、租赁期满，乙方应如期交还该房屋。如乙方逾期归还，在逾期期间应加倍向甲方支付租金。\n        "
              ),
              _c("br"),
              _vm._v(
                "第十三条 甲、乙双方就履行本合同发生纠纷，应通过协商解决；协商解决不成的，可提请房屋租赁主管机关调解或向：租赁房屋所在地人民法院起诉。\n        "
              ),
              _c("br"),
              _vm._v(
                "第十五条 甲乙双方应当签订《房屋租赁安全管理责任书》。\n        "
              ),
              _c("br"),
              _vm._v(
                "第十六条 甲、乙双方应自签订本合同之日起三十日内到房屋租赁主管机关进行登记备案，取得《房屋租赁凭证》。\n        "
              ),
              _c("br"),
              _vm._v(
                "租赁期间，变更、终止本合同的，甲、乙双方签订变更或终止协议，协议须在签订后30日内到原房屋租赁登记备案机关办理变更、注销登记备案手续。\n        "
              ),
              _c("br"),
              _vm._v("本合同为电子合同。自双方签订之时起生效。\n        "),
              _c("br"),
              _vm._v("(附页)\n        "),
              _c("br"),
              _c("h3", [_vm._v("房屋租赁安全管理")]),
              _c("span", { staticClass: "g" }, [_vm._v("责任书")]),
              _c("br"),
              _c("span", { staticClass: "gg" }, [
                _vm._v("人口和房屋综合管理办公室印制")
              ]),
              _c("br"),
              _vm._v(
                "为贯彻执行《人民代表大会常务委员会关于加强房屋租赁安全责任的决定》，进一步明确房屋租赁安全责任，加强出租房屋安全管理，保障人民群众生命财产安全，根据相关法律、法规规定，特制定本责任书：\n        "
              ),
              _c("br"),
              _vm._v(
                "一、本市行政区域内生产经营性用房（包括各类商品市场及其档位、柜台）、办公用房、住宅及其他房屋的出租人和承租人为出租屋安全责任人。\n        "
              ),
              _c("br"),
              _vm._v(
                "二、出租人出租房屋应当有房屋权属证明或者市政府规定的其他证明文件。委托他人出租的，业主应当与受托人签订书面委托协议，约定各自的安全责任。房屋转租人、其他有实际出租行为的人和房屋出借人应当承担出租人安全责任。\n        "
              ),
              _c("br"),
              _vm._v(
                "三、出租人应当保证用于出租的建筑物及其出入口、通道、消防、燃气、电力设施等应符合有关法律、法规的规定以及有关行政部门规定的安全标准。法律、法规规定需取得相关许可证或者批准文件才允许出租的，出租人应当取得。\n        "
              ),
              _c("br"),
              _vm._v(
                "四、承租人利用出租房屋进行生产经营活动的，出租人应当要求其在开业前出示已办理消防手续的相关证明及工商业营业执照或者开业许可证书。\n        "
              ),
              _c("br"),
              _vm._v(
                "五、出租人应当每季度不少于一次对出租房屋的安全使用情况和使用性质进行查看并做好书面记录，承租人予以配合并签字；因客观原因不能亲自查看的，应当委托他人查看。\n        "
              ),
              _c("br"),
              _vm._v(
                "六、出租人查看发现出租房屋存在安全隐患和承租人擅自改变房屋使用性质的情况，应当向出租屋综合管理机构或者其他有关行政部门报告。\n        "
              ),
              _c("br"),
              _vm._v(
                "七、承租人应当按照法律、法规的规定和房屋租赁合同的约定，安全合理使用房屋，不得擅自改变房屋的结构和使用性质；承租人发现出租房屋存在安全隐患的，应当立即通知出租人，并同时报告出租屋综合管理机构或者其他有关行政部门。\n        "
              ),
              _c("br"),
              _vm._v(
                "八、承租人不得擅自改变出租屋使用功能，利用出租屋从事旅馆业、餐饮、娱乐、网吧、作坊等经营性活动必须符合有关规定；\n        "
              ),
              _c("br"),
              _vm._v(
                "禁止利用出租屋从事赌博、吸毒贩毒、卖淫嫖娼、制黄贩黄、伪造证件、承印非法出版物、制造销售假冒伪劣商品、窝藏犯罪人员、窝藏和销售赃物等违法犯罪行为；\n        "
              ),
              _c("br"),
              _vm._v(
                "禁止利用出租屋从事传销或者变相传销、无照经营、无证开办诊所、非法行医和非法从事再生资源回收等违法活动；\n        "
              ),
              _c("br"),
              _vm._v(
                "禁止利用出租屋从事无证职介、婚介、培训、房地产中介等诈骗活动；\n        "
              ),
              _c("br"),
              _vm._v(
                "禁止利用住宅出租屋生产、储存、经营易燃、易爆、有毒、放射性等危险物品。\n        "
              ),
              _c("br"),
              _vm._v(
                "九、租赁双方应当协助和配合出租屋综合管理机构对出租房屋的安全检查和管理，如实提供相关材料和信息。\n        "
              ),
              _c("br"),
              _vm._v(
                "十、出租人或承租人未依法履行安全责任的，导致他人人身、财产受到损害的，受害人可以要求出租人或承租人依法承担相应的赔偿责任。\n        "
              ),
              _c("br"),
              _vm._v(
                "十 一、租户在租期中的安全责任，本着双方能够顺利的履行合约，现与租户签订如下协议：\n        "
              ),
              _c("br"),
              _vm._v(
                "1、乙方入住前，甲方应向乙方告知环境、水、 电 、 消防设施等注意事项。 并履行水、电使用和注意消防安全等告知义务；\n        "
              ),
              _c("br"),
              _vm._v(
                "2、对家庭所有成员和入住人员， 要宣传好各项安全事项，并自行管理到位。 如若发生意外（如死亡、重伤 、轻伤、火灾 、中毒等），由乙方承担全部责任；\n        "
              ),
              _c("br"),
              _vm._v(
                "3、乙方在入住前应对环境和相关设施进行检查， 并对屋内设施完好情况、 使用方法及防火安全等进行确认。 如有问题题及时与甲方联系。如日常检查不到位， 出现问题及造成后果由乙方负责修复或赔偿；\n        "
              ),
              _c("br"),
              _vm._v(
                "4、乙方在租赁期间， 应注意安全用电和消防安全等事项。 因乙方不慎发生的各种事故造成的所有损失由乙方承担， 甲方不承担任何责任；\n        "
              ),
              _c("br"),
              _vm._v(
                "5、如乙方擅自乱接、 改动水电设施， 所发生的一切事故（如死亡、重伤、轻伤、火灾、 中毒等）由乙方承担， 甲方不承担任何责任；\n        "
              ),
              _c("br"),
              _vm._v(
                "6、如乙方私自乱拉、乱接水电设施，发生的事故， 给国家财产、 出租屋财产造成损失， 他人人身 、 财产损失均由乙方承担；\n        "
              ),
              _c("br"),
              _vm._v(
                "7、房屋在租赁期间， 如乙方发现不安全因素， 应及时告知水电等相关部门， 进行维修， 费用由乙方承担。 如不告知或不维修， 发生后果由乙方承担；\n        "
              ),
              _c("br"),
              _vm._v(
                "8、出租屋内，严禁存放液化气罐 、烟花爆竹、汽油等危险化学品， 不得从事违法乱纪活动。对于发生事故的，所产生（包括甲方）的损失，一律由乙方承担；\n        "
              ),
              _c("br"),
              _vm._v("9、乙方需保证所租房屋及公共区域的清洁卫生；\n        "),
              _c("br"),
              _vm._v(
                "10、如乙方在安全方面的实际行动损害到甲方的权益， 甲方有权立即终止与其签订的房屋租赁合同。\n        "
              ),
              _c("br"),
              _vm._v(
                "本协议为电子协议，自甲乙双方签订之日起生效，双方要诚信守法，共同遵守并履行本协议。\n      "
              )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }